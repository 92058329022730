<template>
  <div>
    <el-container>
      <!-- left -->
      <el-aside width="collapse">
        <!-- logo -->
        <div class="logo">
          <div>
            <img src="../../assets/img/logo.png" />
          </div>
          <!--  -->
          <div v-if="authType == 'H'">
            <el-dropdown @command="dropdownHandleCommand">
              <span class="el-dropdown-link">
                {{ storesName }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) in manageStoresList"
                  :key="item.StoresGuid"
                  :command="item"
                >
                  {{ item.StoresName }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!--  -->
          <div v-else>
            <span :class="isCollapse ? 'hide' : 'softName'">
              {{
                isCollapse
                  ? ""
                  : store.StoresName
                  ? store.StoresName
                  : "帝峰科技"
              }}
            </span>
          </div>
        </div>
        <!-- end logo -->
        <!-- el-menu -->
        <el-menu
          class="el-menu-vertical"
          :default-active="
            $route.path.substring(0, $route.path.indexOf('/', 1))
          "
          :collapse="isCollapse"
          :hide-timeout="5000"
          :show-timeout="5000"
          active-text-color="#ffffff"
          router
        >
          <el-menu-item
            v-if="aepFeature ? true : item.path != '/pressing'"
            v-for="(item, index) in elMenuData"
            :key="index"
            :index="item.path"
          >
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </el-menu>
        <!--end el-menu-->
        <div class="close">
          <div class="popover" :class="isCollapse ? 'hidde' : ''">
            <span class="popover-top" @click="logout">
              <span>退出登录</span>
            </span>
            <span>
              <span>{{ authMapName }}</span>
              <i class="el-icon-caret-bottom"></i>
            </span>
          </div>
          <i
            :class="isCollapse ? 'el-icon-s-unfold width' : 'el-icon-s-fold'"
            @click="onCloseMenu"
          ></i>
        </div>
      </el-aside>
      <!-- end left -->
      <!-- right -->
      <el-main class="elMain">
        <router-view ref="routerView" />
      </el-main>
      <!-- end right -->
    </el-container>

    <div v-show="showWarning" class="warning-back"></div>
  </div>
</template>
<script>
import Aep from "@/api/aep.js";

import menuData from "@/components/menu/menu-data.js";

// import signalR from "@/uitls/signalr.js";
export default {
  name: "index",
  data() {
    return {
      elMenuData: menuData.main, // 菜单栏数据
      store: {}, // 门店数据
      storesName: "",
      authType: "",
      authMapName: "",
      manageStoresList: [],

      t_rtcid: "", // 连接需要的唯一性标识id
      aepFeature: 0,
      isCollapse: false, // 控制菜单栏收缩
      showWarning: false,
    };
  },

  created() {
    // this.queryData();
  },

  mounted() {
    let {
      StoresName,
      authType,
      manageStoresList,
      authMapName,
      rtcid,
    } = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    let selectStoreGuid = window.sessionStorage.getItem("selectStoreGuid");
    let text = StoresName;
    let aepFeature = this.storage.getStorage("systemData").feature.AepFeature;

    if (authType == "H") {
      manageStoresList.forEach((item) => {
        if (item.StoresGuid == selectStoreGuid) {
          text = item.StoresName;
        }
      });
    }
    this.store = this.storage.getStorage("systemData").store;
    this.storesName = text;
    this.authType = authType;
    this.authMapName = authMapName;
    this.manageStoresList = manageStoresList;
    this.aepFeature = aepFeature;

    this.t_rtcid = rtcid;

    if (!this.connection && aepFeature) {
      let routerHash = this.storage.getStorage("routerHash") || "";
      if (!routerHash) {
        this.$router.push("/pressing");
        this.storage.setStorage("routerHash", "/pressing");
      }
      // setTimeout(() => {
      //   this.receiveInformationPush({ AlertText: "收到一条紧急报警消息" });
      // }, 3000);
      this.connectionWebSocket();
    }
  },

  beforeDestroy() {
    this.connection.onclose();
  },

  methods: {
    onCloseMenu() {
      this.isCollapse = !this.isCollapse;
    },

    dropdownHandleCommand(event) {
      this.storesName = event.StoresName;
      window.sessionStorage.setItem("selectStoreGuid", event.StoresGuid);
      // console.log(this.$route);
      this.$router.go(0);
    },

    /**
     * signalr通讯
     */
    async connectionWebSocket() {
      // const signalR = require("@microsoft/signalr");
      // const signalR = require("@microsoft/signalr");
      import("@/utils/signalr.js").then(async (signalR) => {
        let api = "https://api.difengsoft.com/signalr-pro/hubs/device?rtcid=";
        // let api = "http://signalr-dev.difengsoft.com/hubs/device?rtcid=";

        this.connection = await new signalR.HubConnectionBuilder()
          .withUrl(api + this.t_rtcid, {})
          .configureLogging(signalR.LogLevel.Error)
          .build();

        this.connection.on("ReceiveUnifiedMessage", (msgInfo) => {
          // console.log("我是水单列表更新", msgInfo);
          if (msgInfo.Code == 8001) {
            this.receiveInformationPush(msgInfo);
          }
          // this.$ownerInstance.callMethod('receiveInformationPush', msgInfo);
        });

        // 自动重连成功后的处理
        this.connection.onreconnected((connectionId) => {});
        // 计时同信启动成功
        // if (this.connection.state !== signalR.HubConnectionState.Connected) {

        // }
        // 生命周期
        this.connection.onreconnecting((error) => {});
        // (默认4次重连)，任何一次只要回调成功，调用
        this.connection.onreconnected((connectionId) => {});

        try {
          await this.connection.start();

          this.connection.onclose(async (error) => {
            this.connection = null;
            if (!this.connection && this.aepFeature) {
              await this.connectionWebSocket(); // 重连
            }
          });
        } catch (err) {
          console.log(err);
          setTimeout(() => {
            this.connection = null;
            if (!this.connection && this.aepFeature) this.connectionWebSocket();
          }, 1000);
        }
      });
    },

    async receiveInformationPush(event) {
      // console.log(event, "ddd");
      this.showWarning = true;
      setTimeout(() => {
        this.showWarning = false;
      }, 8000);
      // window.sessionStorage.setItem("receiveInfoList");
      try {
        await Aep.ensureReceiveMessage({
          msg_id: event.MsgId,
        });
      } catch (err) {
        console.log(err);
      }

      this.$confirm(event.AlertText, "紧急报警提示", {
        type: "warning",
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "前去处理",
        cancelButtonText: "关闭",
      })
        .then(() => {
          // console.log(this.$refs.routerView.$children[0].$children[1].$children[0]);
          if (this.$route.path == "/pressing/manage") {
            return this.$refs.routerView.$children[0].$children[1].$children[0].getMessageList();
            // this.storage.setStorage("routerHash", "/pressing");
          }

          let routerUrl = this.$router.resolve({
            path: "/alarm",
            query: {
              msg_id: event.MsgId,
            },
          });
          window.open(routerUrl.href, "_blank");
        })
        .finally(() => {
          this.showWarning = false;
        });
      // this.$message.error('收到一条紧急报警消息')
    },
  },
};
</script>
<style lang="less" scoped>
.el-container {
  .el-aside {
    position: relative;
    height: 100vh;
    scrollbar-width: none;
    -ms-overflow-style: none;
    // border-right: 1px solid #e6e6e6;
    &::-webkit-scrollbar {
      display: none;
    }
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: calc(100% - 1px);
      padding: 20px 0;
      border-right: 1px solid #e6e6e6;

      > div:first-child {
        height: 40px;
        width: 40px;
        > img {
          height: 40px;
          width: 40px;
        }
      }
      > div:last-child {
        margin-top: 10px;
        > i {
          font-size: 18px;
        }
        > span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          transition: width 0.5s linear;
        }
        .hidde {
          display: none;
        }
      }
      .softName {
        width: 120px;
        height: 20px;
      }
      .hide {
        width: 60px;
        height: 20px;
      }
    }
    ::v-deep .el-menu {
      // border-right: 0;
      height: calc(100vh - 112px - 37px);
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      .el-menu-item {
      }
      .is-active {
        position: relative;
        background-color: #20a0ff;
        box-sizing: border-box;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
    .el-menu-vertical:not(.el-menu--collapse) {
      width: 160px;
    }

    // .el-menu::-webkit-scrollbar {
    //   display: none;
    // }

    .close {
      // position: sticky;
      // bottom: 0;
      height: 37px;
      width: calc(100% - 1px);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-right: 1px solid #e6e6e6;

      > i {
        height: 37px;
        width: 37px;
        line-height: 37px;
        text-align: center;
        font-size: 18px;
        color: #d0d9e3;
      }
      > i:hover {
        background-color: #475669;
      }
      .popover {
        position: relative;
        width: 120px;
        height: 37px;
        display: flex;
        align-items: center;
        .popover-top {
          position: absolute;
          bottom: 37px;
          left: 8px;
          min-height: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          padding: 10px 0;
          display: none;
          width: 140px;
          border-radius: 5px;
          background-color: #fff;
          > span {
            width: 100px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 10px;
          }
          > span:last-child {
            margin-bottom: 0;
          }
        }
        .popover-top::before {
          content: "";
          position: absolute;
          bottom: -9px;
          left: 50%;
          transform: translateX(-50%);
          border-top: 5px solid #fff;
          border-right: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
        }
        > span {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 120px;
          text-align: center;
          margin-right: 5px;
          > span {
            display: inline-block;
            width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
          }
        }
      }
      .popover:hover > .popover-top {
        display: block;
      }
      .hidde {
        display: none;
      }
      .width {
        width: 64px;
      }
    }
  }
  .elMain {
    height: 100vh;
    background-color: #e9eef3;
    color: #333;
    padding: 0;
  }
}

.el-dropdown {
  position: relative;
  padding-right: 16px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  i {
    position: absolute;
    right: 0px;
    top: 2px;
  }
}

.warning-back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 1000px 360px rgba(255, 0, 0, 0.5);
  z-index: 20;
  animation: warning 8s infinite;
}

@keyframes warning {
  0%,
  20%,
  36%,
  48%,
  56%,
  64%,
  68%,
  72%,
  76%,
  80%,
  84%,
  88%,
  92%,
  96%,
  100% {
    box-shadow: inset 0 0 1000px 360px rgba(0, 0, 255, 0.2);
  }
  10%,
  28%,
  42%,
  52%,
  60%,
  66%,
  70%,
  74%,
  78%,
  82%,
  86%,
  90%,
  94%,
  98% {
    box-shadow: inset 0 0 1000px 360px rgba(255, 0, 0, 0.5);
  }
}
</style>
